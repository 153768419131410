import { Line } from 'react-chartjs-2'
import { Chart as ChartJS, LineController, LineElement, PointElement, LinearScale, CategoryScale, Title, Legend, Tooltip, Filler } from 'chart.js'
import { Patient } from '../models/Patient'

ChartJS.register(LineController, LineElement, PointElement, LinearScale, CategoryScale, Title, Legend, Tooltip, Filler)

interface PatientProfilePainGraphProps {
  patient: Patient
}

export const PatientProfilePainGraph = (props: PatientProfilePainGraphProps) => {
  const { patient } = props

  const data = {
    labels: patient.blood_samples_analysis.map((sample) => sample.phase),
    datasets: [
      {
        label: "Inferred",
        data: patient.blood_samples_analysis.map((sample) => sample.report_pain_level),
        backgroundColor: "rgba(255,0,0,0.2)",
        borderColor: "#ff0000d1",
        pointRadius: 6,
        pointBorderColor: 'rgb(255, 0, 0)',
        tension: 0.2,
      },
      {
        label: "Subjective",
        data: patient.blood_samples_analysis.map((sample) => sample.subjective_pain_score),
        backgroundColor: "rgba(0,0,255,0.2)",
        borderColor: "#0000ff",
        pointRadius: 6,
        pointBorderColor: '#0000ff',
        tension: 0.2,
      },
      {
        label: "Expected Low",
        data: [5, 6, 2],
        backgroundColor: "rgba(173,173,173,0.2)",
        borderColor: "#d3d3d3",
        tension: 0.2,
        fill: 1
      },
      {
        label: "Expected High",
        data: [8, 9, 5],
        backgroundColor: "rgba(173,173,173,0.2)",
        borderColor: "#d3d3d3",
        tension: 0.2,
        fill: 1,
      }
    ]
  }

  const options = {
    scales: {
      y:
      {
        min: 0,
        max: 10,
        stepSize: 1,
      }
    }
  }

  return (
    <div className="card rounded-2xl border-none">
      <div className="card-body">
        <div>
          <div className='card__header'>
            <h2>Pain Evolution</h2>
          </div>

          <Line data={data} options={options} width={500} height={250} />
        </div>
      </div>
    </div>
  )
}