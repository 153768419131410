import ReactDOM from 'react-dom/client'
import 'bootstrap/dist/css/bootstrap.min.css'
import GlobalStateProvider from './providers/GlobalState'
import AccountProvider from './providers/AccountState'
import './styles/index.scss'
import App from './App'
import { BrowserRouter } from 'react-router-dom'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

root.render(
  <AccountProvider>
    <BrowserRouter>
      <GlobalStateProvider children={<App />} />
    </BrowserRouter>
  </AccountProvider>
)