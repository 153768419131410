import Table from 'react-bootstrap/Table'
import { Patient } from '../models/Patient'
const moment = require('moment')

interface PatientProfileMedicationsProps {
  patient: Patient
}

export const PatientProfileMedications = (props: PatientProfileMedicationsProps) => {
  const { patient } = props

  const Data = () => {
    if (patient.medications.length === 0) {
      return <>No data to display</>
    } else {
      return (
        <Table hover className='text-sky-900'>
          <thead>
            <tr>
              <th>Medication</th>
              <th>Start Date</th>
              <th>End Date</th>
              <th>Dosage</th>
            </tr>
          </thead>
          <tbody>
            {patient.medications && patient.medications.map((row, index) => {
              return (
                <tr key={row.name}>
                  <td>{row.name}</td>
                  <td>{row.start_date ? moment.utc(row.start_date).local().format(process.env.REACT_APP_DATE_FORMAT) : 'N/A'}</td>
                  <td>{row.end_date ? moment.utc(row.end_date).local().format(process.env.REACT_APP_DATE_FORMAT) : 'N/A'}</td>
                  <td>{row.dosage}</td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      )
    }
  }

  return (
    <div className="card rounded-2xl border-none">
      <div className="card-body">
        <div className='card__header'>
          <h2>Medications</h2>
        </div>

        <Data />
      </div>
    </div>
  )
}