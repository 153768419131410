import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Popover from 'react-bootstrap/Popover'
import Spinner from 'react-bootstrap/Spinner'
import ActiveUploadsBox from './ActiveUploadsBox'
import NotificationsBox from './NotificationsBox'
import { useAccountState } from '../providers/AccountState'
import { useGlobalState } from '../providers/GlobalState'
import { ReactComponent as IconNotifications } from './../assets/icon-notifications.svg'

const Header = () => {
  const [{ userSession, signOut }] = useAccountState()
  const [{ activeUploads }] = useGlobalState()

  const activeUploadsPopover = (
    <Popover>
      <Popover.Body>
        <ActiveUploadsBox />
      </Popover.Body>
    </Popover>
  )

  const notificationsPopover = (
    <Popover>
      <Popover.Body>
        <NotificationsBox />
      </Popover.Body>
    </Popover>
  )

  const ActiveUploads = () => {
    if (activeUploads.length > 0) {
      return (
        <OverlayTrigger trigger="click" placement="bottom" overlay={activeUploadsPopover} rootClose>
          <Spinner variant="primary" animation="border" className='header__notifications-button' />
        </OverlayTrigger>
      )
    }

    return <></>
  }

  const Notifications = () => {
    return (
      <OverlayTrigger trigger="click" placement="bottom" overlay={notificationsPopover} rootClose>
        <IconNotifications className='cursor-pointer fill-sky-900' />
      </OverlayTrigger>
    )
  }

  const signOutPopover = (
    <Popover id="popover-sign-out">
      <Popover.Body>
        <div className='flex flex-column gap-4'>
          <Button variant="link" onClick={signOut}>Sign out</Button>
        </div>
      </Popover.Body>
    </Popover>
  )

  return (
    <div className='flex items-center justify-end gap-x-4'>
      <ActiveUploads />

      <Notifications />

      <Form.Control type="plaintext" placeholder="Search..." className='w-auto' />

      <OverlayTrigger trigger="click" placement="bottom" overlay={signOutPopover} rootClose>
        <div className='cursor-pointer'>
          {userSession?.idToken.payload.given_name} {userSession?.idToken.payload.family_name}
          <span className="tooltip" role="tooltip">
            Click to sign out
          </span>
        </div>
      </OverlayTrigger>
    </div>
  )
}

export default Header