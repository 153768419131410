import { Navigate, Route, Routes } from "react-router-dom"
import AnalysisRequests from './components/AnalysisRequests'
import CreateAnalysisRequest from './components/CreateAnalysisRequest'
import CreatePatientForm from "./components/CreatePatientForm"
import Header from './components/Header'
import Home from './components/Home'
import Login from "./components/Login"
import NavBar from './components/NavBar'
import PatientProfile from './components/PatientProfile'
import Patients from './components/Patients'
import Signup from "./components/Signup"
import ResetPassword from "./components/ResetPassword"
import ToastMessage from './components/ToastMessage'

function App() {
  const UserLayout = () => (
    <div className="flex gap-x-8 p-3 text-sky-900">
      <NavBar />

      <div className='flex flex-column gap-16 grow'>
        <ToastMessage />

        <Header />

        <Routes>
          <Route path='/' element={<Home />} />
          <Route path="patients">
            <Route index element={<Patients />} />
            <Route path=":patientId" element={<PatientProfile />} />
            <Route path="new/" element={<CreatePatientForm />} />
          </Route>

          <Route path="analysis-requests">
            <Route index element={<AnalysisRequests />} />
            <Route path="new/:patientId" element={<CreateAnalysisRequest />} />
          </Route>

          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </div>
    </div>
  )

  const GuestLayout = () => (
    <Routes>
      <Route path="login" element={<Login />} />
      <Route path="signup" element={<Signup />} />
      <Route path="reset-password" element={<ResetPassword />} />
      <Route path="*" element={<Navigate to="/login" replace />} />
    </Routes>
  )

  if (!localStorage.getItem('userSession')) {
    return <GuestLayout />
  }

  return <UserLayout />
}

export default App