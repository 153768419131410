import { useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Spinner from 'react-bootstrap/Spinner'
import { ToastVariant } from './ToastMessage'
import PatientsController from '../controllers/PatientsController'
import { useAccountState } from '../providers/AccountState'
import { useGlobalState } from '../providers/GlobalState'

const CreatePatientForm = () => {
  let navigate = useNavigate()
  const [{ userSession }] = useAccountState()
  const [, setGlobalState] = useGlobalState()
  const [isLoading, setIsLoading] = useState(false)

  async function handleSubmit(e: any) {
    e.preventDefault()

    setIsLoading(true)

    PatientsController.createPatient(
      userSession.idToken.jwtToken,
      e.target.formDob.value,
      e.target.formGender.value,
      e.target.formWeight.value,
      e.target.formHeight.value,
      e.target.formSurgeryName.value,
      e.target.formHistoricalDisease.value
    ).then(function (response: any) {
      setIsLoading(false)

      if (response.status_code === 200) {
        setGlobalState({
          toast: {
            message: `Patient created successfully (ID : ${response.body}).`,
            variant: ToastVariant.Success
          }
        })

        navigate(`/patients/${response.body}`, { replace: true })
      } else {
        setGlobalState({
          toast: {
            message: `Error : ${response.body}`,
            variant: ToastVariant.Error
          }
        })
      }
    })
  }

  return (
    <div className="card rounded-2xl border-none" >
      <div className="card-body">
        <div className='card__header'>
          <h1>Add a New Patient</h1>
        </div>

        <div>
          <Form onSubmit={handleSubmit}>
            <Row className="g-2">
              <Col md>
                <Form.Group controlId="formDob" className="mb-3">
                  <Form.Label>Date of birth</Form.Label>
                  <Form.Control type="date" required />
                </Form.Group>
              </Col>

              <Col md>
                <Form.Group controlId="formGender" className="mb-3">
                  <Form.Label>Gender</Form.Label>
                  <Form.Select required>
                    <option value="Female">Female</option>
                    <option value="Male">Male</option>
                    <option value="Other">Other</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>

            <Row className="g-2">
              <Col md>
                <Form.Group controlId="formWeight" className="mb-3" >
                  <Form.Label>Weight (kg)</Form.Label>
                  <Form.Control type="number" min={0.001} step={0.001} />
                </Form.Group>
              </Col>

              <Col md>
                <Form.Group controlId="formHeight" className="mb-3" >
                  <Form.Label>Height (cm)</Form.Label>
                  <Form.Control type="number" min={10} step={0.01} />
                </Form.Group>
              </Col>
            </Row>

            <Form.Group controlId="formSurgeryName" className="mb-3">
              <Form.Label>Surgery Name</Form.Label>
              <Form.Control type="text" required />
            </Form.Group>

            <Form.Group controlId="formHistoricalDisease" className="mb-3">
              <Form.Label>Historical Disease</Form.Label>
              <Form.Control type="text" />
            </Form.Group>

            <div className='flex gap-x-4'>
              <NavLink to={'/patients'} className='btn btn-outline-secondary'>Cancel</NavLink>

              {isLoading ? <Button variant="primary" type='submit' disabled><Spinner animation="border" variant="light" size="sm" as="span" role="status" aria-hidden="true" /></Button> : <Button variant="primary" type='submit'>Submit</Button>}
            </div>
          </Form>
        </div>
      </div>
    </div>
  )
}

export default CreatePatientForm