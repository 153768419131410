import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Table from 'react-bootstrap/Table'
import LoadingSpinner from './LoadingSpinner'
import AnalysisRequestsController from '../controllers/AnalysisRequestsController'
import { AnalysisRequest } from '../models/AnalysisRequest'
import { MetricInfo } from '../models/MetricInfo'
import { useAccountState } from '../providers/AccountState'

const moment = require('moment')

const AnalysisRequests = () => {
  const navigate = useNavigate()
  const [{ userSession }] = useAccountState()
  const [metricsList, setMetricsList] = useState<Array<MetricInfo>>([])
  const [analysisRequests, setAnalysisRequests] = useState<Array<AnalysisRequest>>([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setIsLoading(true)

    AnalysisRequestsController.getMetrics(userSession.idToken.jwtToken)
      .then(function (response: Array<MetricInfo>) {
        setMetricsList(response)
      })
      .catch(function (error: any) {
        console.log(error)
      })

    AnalysisRequestsController.getAnalysisRequests(userSession.idToken.jwtToken)
      .then(function (response: Array<AnalysisRequest>) {
        setAnalysisRequests(response)
        setIsLoading(false)
      })
      .catch(function (error: any) {
        console.log(error)
      })
  }, [])

  const Metrics = () => {
    return (
      <>
        {metricsList.length > 0 && metricsList.map((metric: MetricInfo) => {
          return (
            <div className="card rounded-2xl border-none select-none" key={metric.key}>
              <div className="card-body metric">
                <span className='text-2xl font-bold text-red-600'>{metric.value}</span>
                <p className='text-base font-semibold'>{metric.display_name}</p>
              </div>
            </div>
          )
        })}
      </>
    )
  }

  const AnalysisRequestsAwaitingFile = () => (
    <div className="card rounded-2xl border-none">
      <div className="card-body">
        <div className='card__header'>
          <h2>Awaiting File</h2>
        </div>
        <Table hover className='mb-0 text-sky-900'>
          <thead>
            <tr>
              <th>Analysis Request ID</th>
              <th>Date Requested</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {analysisRequests.filter((row) => row.status === 'Awaiting File').map((row: AnalysisRequest) => (
              <tr key={row.id} className='cursor-pointer' onClick={() => navigate(`/patients/${row.patient_id}#${row.id}`)}>
                <td>{row.id}</td>
                <td>{moment.utc(row.date_requested).local().format(process.env.REACT_APP_DATETIME_FORMAT)}</td>
                <td><a href='#'>Upload</a></td>
              </tr>
            ))}
          </tbody>
        </Table>

        {isLoading && <LoadingSpinner />}
      </div>
    </div>
  )

  const AnalysisRequestsInProgress = () => (
    <div className="card rounded-2xl border-none">
      <div className="card-body">
        <div className='card__header'>
          <h2>In Progress</h2>
        </div>
        <Table hover className='mb-0 text-sky-900'>
          <thead>
            <tr>
              <th>Analysis Request ID</th>
              <th>Date Requested</th>
              <th>Date Sampled</th>
            </tr>
          </thead>
          <tbody>
            {analysisRequests.filter((row) => row.status === 'In Progress').map((row: AnalysisRequest) => (
              <tr key={row.id} className='cursor-pointer' onClick={() => navigate(`/patients/${row.patient_id}#${row.id}`)}>
                <td>{row.id}</td>
                <td>{moment.utc(row.date_requested).local().format(process.env.REACT_APP_DATETIME_FORMAT)}</td>
                <td>{row.date_sampled?.toString()}</td>
              </tr>
            ))}
          </tbody>
        </Table>

        {isLoading && <LoadingSpinner />}
      </div>
    </div>
  )

  const AnalysisRequestsComplete = () => (
    <div className="card rounded-2xl border-none">
      <div className="card-body">
        <div className='card__header'>
          <h2>Complete</h2>
        </div>
        <Table hover className='mb-0 text-sky-900'>
          <thead>
            <tr>
              <th>Analysis Request ID</th>
              <th>Phase</th>
              <th>Date Requested</th>
              <th>Date Sampled</th>
              <th>Date Processed</th>
            </tr>
          </thead>
          <tbody>
            {analysisRequests.filter((row) => row.status === 'Complete').map((row: AnalysisRequest) => (
              <tr key={row.id} className='cursor-pointer' onClick={() => navigate(`/patients/${row.patient_id}#${row.id}`)}>
                <td>{row.id}</td>
                <td>{row.phase}</td>
                <td>{moment.utc(row.date_requested).local().format(process.env.REACT_APP_DATETIME_FORMAT)}</td>
                <td>{row.date_sampled?.toString()}</td>
                <td>{row.date_processed && moment.utc(row.date_processed).local().format(process.env.REACT_APP_DATETIME_FORMAT)}</td>
              </tr>
            ))}
          </tbody>
        </Table>

        {isLoading && <LoadingSpinner />}
      </div>
    </div>
  )

  return (
    <div className='flex flex-column gap-4'>
      <h1>Today</h1>

      <div className='flex flex-row flex-wrap gap-3'>
        <Metrics />
      </div>

      <div className='flex flex-column gap-3'>
        <h1>Analysis Requests</h1>
        <AnalysisRequestsAwaitingFile />
        <AnalysisRequestsInProgress />
        <AnalysisRequestsComplete />
      </div>
    </div>
  )
}

export default AnalysisRequests