import api from '.';
import { MetricInfo } from '../models/MetricInfo';

export default class HomeController {
  public static async getMetrics(idToken: string): Promise<MetricInfo[]> {
    const response = await api.get("/home", {
      headers: { Authorization: `Bearer ${idToken}` }
    })
    return response.data as MetricInfo[]
  }
}
