import { AuthenticationDetails, CognitoUser, CognitoUserAttribute, CognitoUserSession } from 'amazon-cognito-identity-js'
import { createContext, useContext, useEffect } from 'react'
import { useReducer } from '../utils/hooks'
import Pool from '../utils/UserPool'

const AccountContext = createContext<any>({})

const AccountProvider = (props: any) => {
  const initialState = {
    userSession: JSON.parse(localStorage.getItem('userSession') || '{}') as CognitoUserSession | undefined,
    authenticate,
    confirmPassword,
    forgotPassword,
    getSession,
    signOut,
    signUp
  }

  const [accountState, setAccountState] = useReducer(initialState)

  useEffect(() => {
    refreshSession()
  }, [])

  async function refreshSession() {
    const user = Pool.getCurrentUser()

    if (user != null) {
      user.getSession((_err: any, userSession: CognitoUserSession) => {
        if (new Date().getTime() > userSession.getAccessToken().getExpiration() * 1000) {
          user.refreshSession(userSession.getRefreshToken(), (err: any, refreshedSession: CognitoUserSession) => {
            if (err) {
              console.error(err)
            } else {
              localStorage.setItem('userSession', JSON.stringify(refreshedSession))
              setAccountState({ userSession: refreshedSession })
              window.location.reload()
            }
          })
        }
      })
    }
  }

  async function authenticate(Username: string, Password: string) {
    return await new Promise((resolve, reject) => {
      const user = new CognitoUser({ Username, Pool })
      const authDetails = new AuthenticationDetails({ Username, Password })

      user.authenticateUser(authDetails, {
        onSuccess: (data) => {
          localStorage.setItem('userSession', JSON.stringify(data))
          setAccountState({ userSession: data })
          window.location.reload()
          resolve(data)
        },
        onFailure: (err) => {
          switch (err.name) {
            case 'UserNotConfirmedException':
              err.message = 'Your account is not confirmed. Please check your email for a confirmation link and follow the instructions to complete the registration process.'
              break
            case 'PasswordResetRequiredException':
              err.message = 'You must reset your password before you can continue.'
              break
            case 'TooManyRequestsException':
              err.message = 'Too many failed attempts. Please try again later.'
              break
            case 'NotAuthorizedException' || 'UserNotFoundException':
              err.message = 'Incorrect username or password.'
              break
            case 'ExpiredCodeException':
              err.message = 'The code you entered has expired. Please request a new code and try again.'
              break
            default:
              err.message = 'Something went wrong. Contact guillaume.mercier@alleviatepain.ca for assistance.'
              break
          }

          reject(err)
        },
        newPasswordRequired: (data) => {
          localStorage.setItem('userSession', JSON.stringify(data))
          setAccountState({ userSession: data })
          resolve(data)
        }
      })
    })
  }

  async function confirmPassword(email: string, code: string, newPassword: string) {
    return await new Promise((resolve, reject) => {
      const user = new CognitoUser({ Username: email, Pool })

      user.confirmPassword(code, newPassword, {
        onSuccess: (data) => {
          resolve(data)
        },
        onFailure: (err) => {
          switch (err.name) {
            case 'UserNotConfirmedException':
              err.message = 'Your account is not confirmed. Please check your email for a confirmation link and follow the instructions to complete the registration process.'
              break
            case 'CodeMismatchException':
              err.message = 'The code you entered is incorrect. Please try again.'
              break
            default:
              err.message = 'Something went wrong. Contact guillaume.mercier@alleviatepain.ca for assistance.'
              break
          }
          reject(err)
        }
      })
    })
  }

  async function forgotPassword(Username: string) {
    return await new Promise((resolve, reject) => {
      const user = new CognitoUser({ Username, Pool })

      user.forgotPassword({
        onSuccess: (data) => {
          resolve(data)
        },
        onFailure: (err) => {
          reject(err)
        },
        inputVerificationCode: (data) => {
          resolve(data)
        }
      })
    })
  }

  async function getSession() {
    return await new Promise((resolve, reject) => {
      const user = Pool.getCurrentUser()

      if (user) {
        user.getSession((err: any, session: any) => {
          if (err) {
            reject()
          } else {
            resolve(session)
          }
        })
      } else {
        reject()
      }
    })
  }

  function signOut() {
    const user = Pool.getCurrentUser()

    if (user) {
      user.signOut()
      localStorage.removeItem('userSession')
      setAccountState({ userSession: undefined })
      window.location.reload()
    }
  }

  async function signUp(givenName: string, familyName: string, email: string, password: string) {
    return await new Promise((resolve, reject) => {
      const attributes = [
        new CognitoUserAttribute({
          Name: "given_name",
          Value: givenName,
        }),
        new CognitoUserAttribute({
          Name: "family_name",
          Value: familyName,
        }),
      ]
      Pool.signUp(email, password, attributes, [], (err, data) => {
        if (err) {
          switch (err.name) {
            case 'UsernameExistsException':
              err.message = 'The username you entered already exists. Please choose a different username and try again.'
              break
            case 'NotAuthorizedException':
              err.message = "We're sorry, but sign-up is currently disabled as we are in closed beta. If you are interested in learning more, please contact us at guillaume.mercier@alleviatepain.ca. Thank you for your interest!"
              break
            case 'InvalidParameterException':
              err.message = 'Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one number and one special character.'
              break
            default:
              err.message = 'Something went wrong. Contact guillaume.mercier@alleviatepain.ca.'
              break
          }

          reject(err)
        }
        else
          resolve(data)
      })
    })
  }

  return (
    <AccountContext.Provider value={[accountState, setAccountState]}>
      {props.children}
    </AccountContext.Provider>
  )
}

export const useAccountState = () => useContext(AccountContext)

export default AccountProvider
