import { AxiosResponse } from 'axios';
import api from '.';
import { AnalysisRequest } from '../models/AnalysisRequest';
import { MetricInfo } from '../models/MetricInfo';

export default class AnalysisRequestsController {
  public static async getAnalysisRequests(idToken: string): Promise<AnalysisRequest[]> {
    const response = await api.get("/analysis-requests", {
      headers: { Authorization: `Bearer ${idToken}` }
    })

    return response.data as AnalysisRequest[]
  }

  public static async getMetrics(idToken: string): Promise<MetricInfo[]> {
    const response = await api.get("/analysis-requests/metrics", {
      headers: { Authorization: `Bearer ${idToken}` }
    })

    return response.data as MetricInfo[]
  }

  public static async createAnalysisRequest(idToken: string, patient_id: string, phase: string, sampled_date: string, file_extension: string | null): Promise<AxiosResponse> {
    const response = await api.post("/analysis-requests",
      { patient_id, phase, sampled_date, file_extension },
      { headers: { Authorization: `Bearer ${idToken}` } })

    return response.data
  }
}
