const NotificationsBox = () => {
  return (
    <div className='flex flex-column gap-4'>
      <div className='flex flex-column'>
        <span className='text-base font-bold'>3 new reports came in</span>
        <span>Today</span>
      </div>
    </div>
  )
}

export default NotificationsBox