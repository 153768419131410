import { useState } from 'react'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import Alert from 'react-bootstrap/Alert'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Spinner from 'react-bootstrap/Spinner'
import AnalysisRequestsController from '../controllers/AnalysisRequestsController'
import { useAccountState } from '../providers/AccountState'
import { useGlobalState } from '../providers/GlobalState'
import { ToastVariant } from './ToastMessage'

const axios = require('axios').default

const CreateAnalysisRequest = () => {
  const navigate = useNavigate()
  const params = useParams()
  const [{ userSession }] = useAccountState()
  const [globalState, setGlobalState] = useGlobalState()
  const { activeUploads } = globalState
  const [file, setFile] = useState<File>()
  const [errorMessage, setErrorMessage] = useState("")
  const [isLoading, setIsLoading] = useState(false)

  async function handleSubmit(e: any) {
    e.preventDefault()

    setIsLoading(true)

    const file_extension = file ? file.name.split('.').pop() : null

    AnalysisRequestsController.createAnalysisRequest(
      userSession.idToken.jwtToken,
      params.patientId as string,
      e.target.phase.value,
      e.target.formDate.value,
      file_extension || null
    )
      .then(async function (response: any) {
        file && uploadToS3(response)

        if (response.status_code === 400) {
          setErrorMessage(response.error_message)
        } else {
          setGlobalState({
            toast: {
              message: `Analysis request created successfully (Patient ID : ${params.patientId}).`,
              variant: ToastVariant.Success
            }
          })
          navigate(`/patients/${params.patientId}`, { replace: true })
        }

        setIsLoading(false)
      })
      .catch(function (error: any) {
        console.log(error)
      })
  }

  const AlertMessage = () => {
    if (errorMessage) {
      return (
        <Alert variant="danger">
          {errorMessage}
        </Alert>
      )
    }

    return <></>
  }

  const uploadToS3 = async (payload: any) => {
    const { url, fields } = payload
    var formData = new FormData()
    Object.keys(fields).forEach(key => {
      formData.append(key, fields[key])
    })

    file && formData.append('file', file)

    let config = {
      onUploadProgress: function (progressEvent: any) {
        // let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        // console.log(percentCompleted)

        // activeUploads[0].progress = percentCompleted
      }
    }

    axios.post(url, formData, config).then(function (response: any) {
      setGlobalState({ activeUploads: [] })
    })

    setGlobalState({ activeUploads: activeUploads.concat({ key: fields.key, progress: 0, status: 'In Progress' }) })
  }

  return (
    <div>
      <AlertMessage />

      <div className="card rounded-2xl border-none">
        <div className="card-body">
          <div className='card__header'>
            <h1>Create an Analysis Request for Patient ID {params.patientId}</h1>
          </div>

          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formPhase" className='mb-3'>
              <Form.Label>Test Phase</Form.Label>
              <div className='flex gap-x-2'>
                <input type="radio" id='pre' value="Pre-Surgery" name="phase" defaultChecked />
                <label htmlFor='pre'>Pre-Surgery</label>
                <input type="radio" id='sur' value="Surgery" name="phase" />
                <label htmlFor='sur'>Surgery</label>
                <input type="radio" id='post' value="Post-Surgery" name="phase" />
                <label htmlFor='post'>Post-Surgery</label>
              </div>
            </Form.Group>

            <Form.Group controlId="formFile" className='mb-3'>
              <Form.Label>Results file</Form.Label>
              <Form.Control type="file" onChange={(e: React.ChangeEvent<HTMLInputElement>) => e.target.files && setFile(e.target.files[0])} />
            </Form.Group>

            <Form.Group controlId="formDate" className='mb-3'>
              <Form.Label>Date sampled</Form.Label>
              <Form.Control type="date" />
            </Form.Group>

            <div className='flex gap-x-4'>
              <NavLink to={`/patients/${params.patientId}`} className='btn btn-outline-secondary'>Cancel</NavLink>

              {isLoading ?
                <Button variant="primary" type='submit' disabled><Spinner animation="border" variant="light" size="sm" as="span" role="status" aria-hidden="true" /></Button> :
                <Button variant="primary" type='submit'>Submit</Button>}
            </div>
          </Form>
        </div>
      </div>
    </div>
  )
}

export default CreateAnalysisRequest