import Spinner from 'react-bootstrap/Spinner'

const LoadingSpinner = () => {
  return (
    <div className='text-center text-sky-900'>
      <Spinner animation="border" />
    </div>
  )
}

export default LoadingSpinner