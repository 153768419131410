import Toast from 'react-bootstrap/Toast'
import ToastContainer from 'react-bootstrap/ToastContainer'
import { useGlobalState } from '../providers/GlobalState'
import './../styles/ToastMessage.scss'

export enum ToastVariant {
  Success = 'toast-message--success',
  Warning = 'toast-message--warning',
  Error = 'toast-message--error',
}

const ToastMessage = () => {
  const [globalState, setGlobalState] = useGlobalState()
  const { toast } = globalState
  const { message, variant } = toast

  return (
    <ToastContainer className="p-3" position={'top-center'}>
      <Toast onClose={() => setGlobalState({ toast: { message: undefined, variant: undefined } })} show={toast.message !== undefined} delay={6000}>
        <Toast.Body className={variant}>{message}</Toast.Body>
      </Toast>
    </ToastContainer>
  )
}

export default ToastMessage