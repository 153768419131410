import { useState } from "react"
import { Alert } from "react-bootstrap"
import { Link, useNavigate } from "react-router-dom"
import AppLogo from './AppLogo'
import { useAccountState } from "../providers/AccountState"

const ResetPassword = () => {
  const navigate = useNavigate()
  const [accountState] = useAccountState()
  const { forgotPassword, confirmPassword } = accountState
  const [error, setError] = useState<string | null>(null)
  const [isEmailSent, setIsEmailSent] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [email, setEmail] = useState("")

  const handleEmailSubmit = (event: React.FormEvent<HTMLFormElement>, email: string) => {
    event.preventDefault()

    setEmail(email)

    setIsLoading(true)
    forgotPassword(email)
      .catch((error: Error) => {
        setError(error.message)
      })
      .then((data: any) => {
        setIsEmailSent(true)
        setIsLoading(false)
      })
  }

  const handleNewPaswordSubmit = (event: React.FormEvent<HTMLFormElement>, resetCode: string, newPassword: string, confirmedNewPassword: string) => {
    event.preventDefault()

    if (newPassword !== confirmedNewPassword) {
      setError("The passwords you entered do not match. Please try again and make sure to enter the same password in both fields.")
      return
    }

    setIsLoading(true)
    confirmPassword(email, resetCode, newPassword)
      .then((data: any) => {
        navigate("/login", { state: { successMessage: "Your password has been reset successfully." } })
      })
      .catch((error: Error) => {
        setError(error.message)
      })
      .then(setIsLoading(false))

  }

  const SendCodeByEmail = () => {
    const [email, setEmail] = useState("")

    return (
      <>
        <div className="flex flex-column">
          <h2 className="mt-6 text-center text-3xl font-extrabold text-sky-900">Reset your password</h2>
          <p className="mt-3 text-sm max-w-xs self-center text-center text-sky-900">Enter your email address and we will send you instructions to reset your password.</p>
        </div>

        <form className="mt-4 space-y-6" onSubmit={(event) => handleEmailSubmit(event, email)}>
          {error && <Alert variant="danger">{error}</Alert>}

          <div>
            <label htmlFor="email-address" className="sr-only">Email address</label>
            <input
              id="email-address"
              name="email"
              type="email"
              autoComplete="email"
              required
              className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm mt-2"
              placeholder="Email address"
              onChange={(event) => setEmail(event.target.value)}
            />
          </div>

          <div>
            <button
              type="submit"
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-sky-800 hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-600"
              disabled={isLoading}>
              {isLoading ? "Loading..." : "Continue"}
            </button>
          </div>

          <p className="mt-2 text-center text-sm text-sky-900">
            Back to login?{" "}
            <Link to="/login" className="font-medium text-sky-900 hover:text-sky-700">
              Sign in
            </Link>
          </p>
        </form>
      </>
    )
  }

  const EnterCode = () => {
    const [resetCode, setResetCode] = useState("")
    const [newPassword, setNewPassword] = useState("")
    const [confirmedNewPassword, setConfirmedNewPassword] = useState("")

    return (
      <>
        <div className="flex flex-column">
          <h2 className="mt-6 text-center text-3xl font-extrabold text-sky-900">Reset your password</h2>
          <p className="mt-3 text-sm max-w-xs self-center text-center text-sky-900">Enter the code you received by email and set a new password.</p>
        </div>

        <form className="mt-4 space-y-6" onSubmit={(event) => handleNewPaswordSubmit(event, resetCode, newPassword, confirmedNewPassword)}>
          {error && <Alert variant="danger">{error}</Alert>}

          <div>
            <label htmlFor="reset-code" className="sr-only">Email address</label>
            <input
              id="reset-code"
              name="reset-code"
              type="number"
              required
              className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm mt-2"
              placeholder="Reset Code"
              onChange={(event) => setResetCode(event.target.value)}
            />

            <label htmlFor="password" className="sr-only">Email address</label>
            <input
              id="password"
              name="password"
              type="password"
              required
              autoComplete="new-password"
              className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm mt-2"
              placeholder="New Password"
              onChange={(event) => setNewPassword(event.target.value)}
            />

            <label htmlFor="confirm-password" className="sr-only">Email address</label>
            <input
              id="confirm-password"
              name="confirm-password"
              type="password"
              required
              autoComplete="confirm-password"
              className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm mt-2"
              placeholder="Confirm New Password"
              onChange={(event) => setConfirmedNewPassword(event.target.value)}
            />
          </div>

          <div>
            <button
              type="submit"
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-sky-800 hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-600"
              disabled={isLoading}>
              {isLoading ? "Loading..." : "Continue"}
            </button>
          </div>

          <p className="mt-2 text-center text-sm text-sky-900">
            Back to login?{" "}
            <Link to="/login" className="font-medium text-sky-900 hover:text-sky-700">
              Sign in
            </Link>
          </p>
        </form>
      </>
    )
  }

  return (
    <div className="min-h-screen flex flex-column items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <AppLogo />

      <div className="max-w-md w-96 space-y-8">
        {isEmailSent ? <EnterCode /> : <SendCodeByEmail />}
      </div>
    </div>
  )
}

export default ResetPassword