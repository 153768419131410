import { useEffect, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import Form from 'react-bootstrap/Form'
import Table from 'react-bootstrap/Table'
import { Patient } from '../models/Patient'
import LoadingSpinner from './LoadingSpinner'
import PatientsController from '../controllers/PatientsController'
import { useAccountState } from '../providers/AccountState'

const moment = require('moment')

const Patients = () => {
  const navigate = useNavigate()
  const [{ userSession }] = useAccountState()
  const [patientsList, setPatientsList] = useState<Array<Patient>>([])
  const [sortByAttribute, setSortByAttribute] = useState('latest_analysis_request')
  const [filterByAttribute, setFilterByAttribute] = useState('all')
  const [orderBy, setOrderBy] = useState('desc')
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setIsLoading(true)

    PatientsController.getPatients(userSession.idToken.jwtToken)
      .then(function (response: Patient[]) {
        setPatientsList(sortResults(response))
        setIsLoading(false)
      })
      .catch(function (error: any) {
        console.log(error)
      })
  }, [])

  useEffect(() => {
    patientsList && setPatientsList(sortResults(patientsList))
  }, [sortByAttribute, orderBy])

  const sortResults = (resultsToSort: Array<Patient>) => {
    let sortedList: Array<Patient> = []

    if (resultsToSort)
      sortedList = [...resultsToSort].sort((a, b) => {
        try {
          if (orderBy === 'asc') {
            switch (sortByAttribute) {
              case 'test_phase':
                return a.test_phase.localeCompare(b.test_phase)
              case 'latest_analysis_request':
                return a.latest_analysis_request.localeCompare(b.latest_analysis_request)
              case 'dob':
                return a.dob.toLocaleString().localeCompare(b.dob.toLocaleString())
              default:
                return 0
            }
          } else {
            switch (sortByAttribute) {
              case 'test_phase':
                return b.test_phase.localeCompare(a.test_phase)
              case 'latest_analysis_request':
                return b.latest_analysis_request.localeCompare(a.latest_analysis_request)
              case 'dob':
                return b.dob.toLocaleString().localeCompare(a.dob.toLocaleString())
              default:
                return 0
            }
          }
        } catch (e) {
          return 0
        }
      })

    return sortedList
  }

  const PatientsTable = () => {
    return (
      <Table hover className='text-sky-900'>
        <thead>
          <tr>
            <th>Patient ID</th>
            <th>Test Phase</th>
            <th>Date of Birth</th>
            <th>Latest Analysis Request</th>
          </tr>
        </thead>
        <tbody>
          {patientsList && patientsList.filter(item => filterByAttribute === 'all' || item.test_phase === filterByAttribute).map((patient: Patient) => {
            return (
              <tr key={patient.id} className='cursor-pointer' onClick={() => navigate(`/patients/${patient.id}`)}>
                <td>{patient.id}</td>
                <td>{patient.test_phase ? patient.test_phase : 'N/A'}</td>
                <td>{patient.dob.toString()}</td>
                <td>{(patient.latest_analysis_request && moment.utc(patient.latest_analysis_request).local().format(process.env.REACT_APP_DATETIME_FORMAT)) || 'N/A'}</td>
              </tr>
            )
          })}
        </tbody>
      </Table>
    )
  }

  return (
    <div className='flex flex-column gap-4'>
      <NavLink to={'/patients/new'} className='btn btn-primary self-end'>Add New Patient</NavLink>

      <div className="card rounded-2xl border-none">
        <div className="card-body">
          <div className='card__header'>
            <h1>Patients</h1>

            <div className='flex items-center gap-2.5'>
              <label htmlFor="patients-list__order-by" className='whitespace-nowrap'>Test Phase : </label>

              <Form.Select id="patients-list__filter-by" className='w-auto text-sky-900' onChange={e => { setFilterByAttribute(e.target.value) }}>
                <option value="all">All</option>
                <option value="Pre-Surgery">Pre-Surgery</option>
                <option value="Surgery">Surgery</option>
                <option value="Post-Surgery">Post-Surgery</option>
              </Form.Select>

              <label htmlFor="patients-list__order-by" className='whitespace-nowrap'>Order by : </label>

              <Form.Select id="patients-list__order-by" className='w-auto text-sky-900' onChange={e => { setSortByAttribute(e.target.value) }}>
                <option value="latest_analysis_request">Latest Analysis Request</option>
                <option value="test_phase">Test Phase</option>
                <option value="dob">Date of Birth</option>
              </Form.Select>

              <Form.Select id="patients-list__direction" className='w-auto text-sky-900' onChange={e => { setOrderBy(e.target.value) }}>
                <option value="desc">DESC</option>
                <option value="asc">ASC</option>
              </Form.Select>
            </div>

          </div>
          <PatientsTable />

          {isLoading && <LoadingSpinner />}
        </div>
      </div>
    </div>
  )
}

export default Patients