import { ProgressBar } from 'react-bootstrap'
import { ActiveUpload } from '../models/ActiveUpload'
import { useGlobalState } from '../providers/GlobalState'

const ActiveUploadsBox = () => {
  const [globalState] = useGlobalState()
  const { activeUploads } = globalState

  const ActiveUploadsList = () => {
    return (activeUploads.map(function (item: ActiveUpload) {
      return (
        <div>
          <span>{item.key}</span>
          <ProgressBar animated now={item.progress} label={`${item.progress}%`} />
        </div>
      )
    }))
  }

  return (
    <div className='flex flex-column w-64 gap-4'>
      <ActiveUploadsList />
    </div>
  )
}

export default ActiveUploadsBox