import { useEffect, useState } from 'react'
import HomeController from '../controllers/HomeController'
import { MetricInfo } from '../models/MetricInfo'
import { useAccountState } from '../providers/AccountState'

const Home = () => {
  const [{ userSession }] = useAccountState()
  const [metricsList, setMetricsList] = useState<Array<MetricInfo>>([])

  useEffect(() => {
    HomeController.getMetrics(userSession.idToken.jwtToken)
      .then(function (response: Array<MetricInfo>) {
        setMetricsList(response)
      })
      .catch(function (error: any) {
        console.error(error)
      })
  }, [])

  const Metrics = () => {
    return (
      <>
        {metricsList.length > 0 && metricsList.map((metric: MetricInfo) => {
          return (
            <div className="card rounded-2xl border-none select-none" key={metric.key}>
              <div className="card-body">
                <span className='text-2xl font-bold text-red-600'>{metric.value}</span>
                <p className='text-base font-semibold text-sky-900'>{metric.display_name}</p>
              </div>
            </div>
          )
        })}
      </>
    )
  }

  return (
    <div className='flex flex-column gap-4'>
      <h1>Today</h1>

      <div className='flex flex-row flex-wrap gap-3'>
        <Metrics />
      </div>
    </div>
  )
}

export default Home