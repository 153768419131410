import Table from 'react-bootstrap/Table'
import { NavLink } from 'react-router-dom'
import { Patient } from '../models/Patient'
const moment = require('moment')

interface PatientProfileBloodSamplesProps {
  patient: Patient
}

export const PatientProfileBloodSamples = (props: PatientProfileBloodSamplesProps) => {
  const { patient } = props

  const Data = () => {
    if (patient.blood_samples_analysis.length === 0) {
      return <>No data to display</>
    } else {
      return (
        <Table hover className='text-sky-900'>
          <thead>
            <tr>
              <th>Phase</th>
              <th>Status</th>
              <th>Inferred Pain</th>
              <th>Subjective Pain</th>
              <th>Requested</th>
              <th>Sampled</th>
              <th>Processed</th>
            </tr>
          </thead>
          <tbody>
            {patient.blood_samples_analysis && patient.blood_samples_analysis.map((row, index) => {
              return (
                <tr key={row.id} id={row.id} className={row.report_url ? 'cursor-pointer' : ''} onClick={() => row.report_url ? window.open(row.report_url, '_blank', 'noreferrer') : null} tabIndex={0}>
                  <td>{row.phase}</td>
                  <td>{row.status}</td>
                  <td align='center'>{row.report_pain_level}</td>
                  <td align='center'>{row.subjective_pain_score}</td>
                  <td>{moment.utc(row.date_requested).local().format(process.env.REACT_APP_DATETIME_FORMAT)}</td>
                  <td>{row.date_sampled && row.date_sampled.toString()}</td>
                  <td>{row.date_processed && moment.utc(row.date_processed).local().format(process.env.REACT_APP_DATETIME_FORMAT)}</td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      )
    }
  }

  return (
    <div className="card rounded-2xl border-none">
      <div className="card-body">
        <div className='card__header'>
          <h2>Blood Samples Analysis</h2>

          <NavLink to={`/analysis-requests/new/${patient.id}`} className='btn btn-outline-primary'>Create an Analysis Request</NavLink>
        </div>

        <Data />
      </div>
    </div>
  )
}