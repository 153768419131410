import { createContext, useContext } from 'react'
import { ToastVariant } from '../components/ToastMessage'
import { ActiveUpload } from '../models/ActiveUpload'
import { useReducer } from '../utils/hooks'

export const GlobalStateContext = createContext<any>({})

const GlobalStateProvider = ({ children, ...rest }: any) => {
  const initialState = {
    activeUploads: Array<ActiveUpload>(),
    toast: {
      message: undefined,
      variant: undefined as ToastVariant | undefined
    }
  }

  const useGlobalState = useReducer(initialState)

  return (
    <GlobalStateContext.Provider value={useGlobalState} {...rest}>
      {children}
    </GlobalStateContext.Provider>
  )
}

export const useGlobalState = () => useContext(GlobalStateContext)

export default GlobalStateProvider
