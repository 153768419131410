import { useEffect, useState } from 'react'
import { NavLink, useParams, useLocation } from 'react-router-dom'
import { PatientProfileBloodSamples } from './PatientProfileBloodSamples'
import { PatientProfileInfo } from './PatientProfileInfo'
import { PatientProfilePainGraph } from './PatientProfilePainGraph'
import { Patient } from '../models/Patient'
import LoadingSpinner from './LoadingSpinner'
import { PatientProfileMedications } from './PatientProfileMedications'
import PatientsController from '../controllers/PatientsController'
import { useAccountState } from '../providers/AccountState'

const PatientProfile = () => {
  const [{ userSession }] = useAccountState()
  const params = useParams()
  const location = useLocation()
  const [patient, setPatient] = useState<Patient>()

  useEffect(() => {
    PatientsController.getPatientById(userSession.idToken.jwtToken, params.patientId!)
      .then((response: Patient) => {
        setPatient(response)
      })
      .catch(function (error: any) {
        console.error(error)
      })
  }, [location])

  useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1))
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" })
        elem.classList.add('highlight')
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
    }
  })

  if (patient === undefined) {
    return <LoadingSpinner />
  }

  return (
    <div className='flex flex-column gap-3'>
      <NavLink to={'/patients'} className='w-fit'>Back to Patients</NavLink>

      <h1>Patient ID: {params.patientId}</h1>

      <div className='flex flex-wrap items-start gap-4'>
        <PatientProfileInfo patient={patient} />

        <PatientProfilePainGraph patient={patient} />

        <PatientProfileMedications patient={patient} />

        <PatientProfileBloodSamples patient={patient} />
      </div>
    </div>
  )
}

export default PatientProfile