import { useState } from "react"
import { Alert } from "react-bootstrap"
import { Link, useNavigate } from "react-router-dom"
import AppLogo from './AppLogo'
import { useAccountState } from "../providers/AccountState"

const Signup = () => {
  const navigate = useNavigate()
  const [{ signUp }] = useAccountState()
  const [givenName, setGivenName] = useState("")
  const [familyName, setFamilyName] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [error, setError] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    setIsLoading(true)

    signUp(givenName, familyName, email, password)
      .then(() => {
        navigate("/login", { state: { successMessage: "Your account has been created. Please check your email for a confirmation link to complete the registration process." } })
      })
      .catch((error: Error) => { setError(error.message) })
      .then(setIsLoading(false))
  }

  return (
    <div className="min-h-screen flex flex-column items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <AppLogo />

      <div className="max-w-md w-96 space-y-8">
        <div>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-sky-900">Create a new account</h2>
        </div>

        <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
          {error && <Alert variant="danger">{error}</Alert>}

          <div className="rounded-md -space-y-px">
            <div>
              <label htmlFor="given-name" className="sr-only">Given Name</label>
              <input
                id="given-name"
                name="given-name"
                type="text"
                autoComplete="given-name"
                required
                className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                placeholder="Given Name"
                value={givenName}
                onChange={(event) => setGivenName(event.target.value)}
              />
            </div>

            <div>
              <label htmlFor="family-name" className="sr-only">Family Name</label>
              <input
                id="family-name"
                name="family-name"
                type="text"
                autoComplete="family-name"
                required
                className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm mt-2"
                placeholder="Family Name"
                value={familyName}
                onChange={(event) => setFamilyName(event.target.value)}
              />
            </div>

            <div>
              <label htmlFor="email-address" className="sr-only">Email address</label>
              <input
                id="email-address"
                name="email"
                type="email"
                autoComplete="email"
                required
                className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm mt-2"
                placeholder="Email address"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
              />
            </div>

            <div>
              <label htmlFor="password" className="sr-only">Password</label>
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                required
                className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm mt-2"
                placeholder="Password"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
              />
            </div>
          </div>

          <div>
            <button
              type="submit"
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-sky-800 hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-600"
              disabled={isLoading}>
              {isLoading ? "Loading..." : "Sign up"}
            </button>
          </div>

          <p className="mt-2 text-center text-sm text-sky-900">
            Already have an account?{" "}

            <Link to="/login" className="font-medium text-sky-900 hover:text-sky-700">
              Sign in
            </Link>
          </p>
        </form>
      </div>
    </div>
  )
}

export default Signup