import { useState } from 'react'
import Button from 'react-bootstrap/Button'
import Table from 'react-bootstrap/Table'
import { Patient } from '../models/Patient'

const moment = require('moment')

interface PatientProfileInfoProps {
  patient: Patient
}

interface PatientInfo {
  name?: string,
  value?: any
}

export const PatientProfileInfo = (props: PatientProfileInfoProps) => {
  const { patient } = props
  const [infoArray] = useState(new Array<PatientInfo>(
    { name: 'Test Phase', value: patient.test_phase ? patient.test_phase : 'N/A' },
    { name: 'Date of Birth', value: `${patient.dob} (${moment(new Date()).diff(moment(new Date(patient.dob)), 'years')} years old)` },
    { name: 'Gender', value: patient.gender },
    { name: 'Weight', value: patient.weight < 1 ? `${patient.weight * 1000} g` : `${patient.weight} kg` },
    { name: 'Height', value: `${patient.height} cm` },
    { name: 'Surgery Name', value: patient.surgery_name },
    { name: 'Historical Disease', value: patient.historical_disease },
    { name: 'Patient Since', value: `${patient.created_at}` }
  ))

  const InfoTable = () => {
    return (
      <Table className='text-sky-900'>
        <tbody>
          {infoArray && infoArray.map((value, index) => {
            return (
              <tr key={index}>
                <td><strong>{value.name}</strong></td>
                <td>{value.value}</td>
              </tr>
            )
          })}
        </tbody>
      </Table>
    )
  }

  return (
    <div className="card rounded-2xl border-none">
      <div className="card-body">
        <div>
          <div className='card__header'>
            <h2>Information</h2>

            <Button variant="outline-primary">Edit</Button>
          </div>

          <InfoTable />
        </div>
      </div>
    </div>
  )
}