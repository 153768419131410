import { Nav } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import AppLogo from './AppLogo'
import NavBarItem from './NavBarItem'
import { ReactComponent as IconBlood } from './../assets/icon-blood.svg'
import { ReactComponent as IconDashboard } from './../assets/icon-dashboard.svg'
import { ReactComponent as IconPerson } from './../assets/icon-person.svg'

const NavBar = () => {
  return (
    <aside className='flex flex-column items-start justify-start min-w-max gap-8 pt-2'>
      <Link to={'/'}>
        <AppLogo />
      </Link>

      <Nav defaultActiveKey="/home" className="flex flex-column w-full gap-1.5">
        <NavBarItem text='Home' to='/' icon={IconDashboard} />
        <NavBarItem text='Patients' to='/patients' icon={IconPerson} />
        <NavBarItem text='Analysis Requests' to='/analysis-requests' icon={IconBlood} />
      </Nav>
    </aside>
  )
}

export default NavBar