import { NavLink } from "react-router-dom"
import './../styles/NavBarItem.scss'

interface NavBarItemProps {
  text: string
  to: string
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
}

const NavBarItem = (props: NavBarItemProps) => {
  return (
    <NavLink className={(navData) => navData.isActive ? "nav-link-item nav-link-item--active" : "nav-link-item"} to={props.to}>
      <div className="navbar-item__logo-container">
        <props.icon className="navbar-item__logo" />
      </div>

      {props.text}
    </NavLink>
  )
}

export default NavBarItem