import { AxiosResponse } from 'axios';
import api from '.';
import { Patient } from '../models/Patient'

export default class PatientsController {
  public static async getPatients(idToken: string): Promise<Patient[]> {
    const response = await api.get(`/patients`, {
      headers: { Authorization: `Bearer ${idToken}` }
    })

    return response.data as Patient[]
  }

  public static async getPatientById(idToken: string, id: string): Promise<Patient> {
    const response = await api.get(`/patients/${id}`, {
      headers: { Authorization: `Bearer ${idToken}` }
    })

    return response.data as Patient
  }

  public static async createPatient(idToken: string, dob: string, gender: string, weight: string, height: string, surgery_name: string, historical_disease: string): Promise<AxiosResponse> {
    const response = await api.post(`/patients`, { dob, gender, weight, height, surgery_name, historical_disease }, {
      headers: { Authorization: `Bearer ${idToken}` }
    })

    return response.data
  }
}
